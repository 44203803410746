$(function () {
  const $productsTechnologiesSliderContainers = $(
    '.js-products-technologies-new-slider-container'
  );

  if ($productsTechnologiesSliderContainers.length) {
    $productsTechnologiesSliderContainers.each(
      function () {
        const $productsTechnologiesSliderContainer = $(this);

        $productsTechnologiesSliderContainer
          .find('.js-products-technologies-new-slider')
          .slick({
            dots: false,
            arrows: true,
            speed: 500,
            slidesToScroll: 1,
            slidesToShow: 2,
            autoplay: false,
            infinite: false,
            centerMode: false,
            prevArrow: $productsTechnologiesSliderContainer.find(
              '.js-products-technologies-new-slider-prev'
            ),
            nextArrow: $productsTechnologiesSliderContainer.find(
              '.js-products-technologies-new-slider-next'
            ),
            responsive: [
              {
                breakpoint: 1250,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          });
      }
    );
  }
});
