require('slick-carousel')
require('jquery-parallax.js')
import './tracking/index.js'
import './components/sliders/products-solutions-new-slider.js'
import './components/sliders/products-technologies-new-slider.js'

const faqCategoriesMenu = document.querySelector('.js-faq-categories');
const header = document.querySelector('header');
const faqs = document.querySelector('.js-faqs');
let sticky = false;//Status of menu


(() => {
  responsive();

  window.addEventListener('resize', function () {
    responsive();
  });

  $('[data-toggle="tooltip"]').tooltip();

  // Popup
  const $popup = document.querySelector('.js-popup')

  const closePopup = $popup => {
    document.body.classList.remove('popup-open')
    $popup.parentElement.removeChild($popup)
    sessionStorage.setItem($popup.dataset.id, true)
  }

  if ($popup) {
    const id = $popup.dataset.id
    if (sessionStorage.getItem(id)) {
      closePopup($popup);
    } else {
      document.body.classList.add('popup-open')
      const $closeBtns = $popup.querySelectorAll('.js-popup-close')
      $closeBtns.forEach($btn => {
        $btn.addEventListener('click', () => closePopup($popup))
      })
    }
  }

  // mobile menu
  const menuBtn = document.querySelector('.js-mobile-menu');

  if (menuBtn) {
    menuBtn.addEventListener('click', function () {
  		document.body.classList.toggle('mobile-menu-active');
  	});
  }

  // Home top animation
  const homeTop = document.querySelector('.hero');

  if (homeTop) {
    let lastScrollPos = 0;
    let rotation = 2;
    let yPos = 0;

    window.addEventListener('scroll', function(){

      if (window.pageYOffset >= 50 &&
      window.pageYOffset <= homeTop.offsetTop + homeTop.offsetHeight - 300) {
        let scrollSpeed = Math.abs(checkScrollSpeed());
        let deg = 0.01 * scrollSpeed;
        let yHeight = ((0.125 * scrollSpeed) > 4) ? 4 : 0.125 * scrollSpeed;

        if (window.pageYOffset > lastScrollPos){
          // downscroll
          if (rotation > 0) {
            rotation -= deg;
          }

          if (yPos < 26) {
            yPos += yHeight;
          }
        } else {
          // upscroll
          if (rotation < 2) {
            rotation += deg;

          }

          if (yPos > 0) {
            yPos -= yHeight;
          }
        }

        if (rotation < 0) {
          rotation = 0;
        } else if (rotation > 2){
          rotation = 2;
        }

        if (yPos < 0) {
          yPos = 0;
        } else if (yPos > 26){
          yPos = 26;
        }

        homeTop.pseudoStyle("after","transform","rotate(" + rotation + "deg) translateY(" + yPos + "px)");
      }

      lastScrollPos = window.pageYOffset <= 0 ? 0 : window.pageYOffset; // For Mobile or negative scrolling
    });
  }

  let checkScrollSpeed = (function(settings){
      settings = settings || {};

      let lastPos, newPos, timer, delta,
          delay = settings.delay || 50; // in "ms" (higher means lower fidelity )

      function clear() {
        lastPos = null;
        delta = 0;
      }

      clear();

      return function(){
        newPos = window.scrollY;
        if ( lastPos != null ){ // && newPos < maxScroll
          delta = newPos -  lastPos;
        }
        lastPos = newPos;
        clearTimeout(timer);
        timer = setTimeout(clear, delay);
        return delta;
      };
  })();

  let UID = {
  	_current: 0,
  	getNew: function(){
  		this._current++;
  		return this._current;
  	}
  };

  HTMLElement.prototype.pseudoStyle = function(element,prop,value){
  	var _this = this;
  	var _sheetId = "pseudoStyles";
  	var _head = document.head || document.getElementsByTagName('head')[0];
  	var _sheet = document.getElementById(_sheetId) || document.createElement('style');
  	_sheet.id = _sheetId;
  	var className = "pseudoStyle" + UID.getNew();

  	_this.className +=  " "+className;

  	_sheet.innerHTML += " ."+className+":"+element+"{"+prop+":"+value+"}";
  	_head.appendChild(_sheet);
  	return this;
  };

  // Home top carousel
  const homeTopCarousel = document.querySelector('.hero.image-carousel');

  if (homeTopCarousel) {
    $('.hero.image-carousel').slick({
        dots: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        fade: true,
    });
  }

  // Checkboxes
  /*let index;
  let checkboxCount;
  let formChecks = document.getElementsByClassName('form-check');

  for(index = 0; index < formChecks.length; ++index) {
    let inputFields = formChecks[index].getElementsByClassName('js-checkbox-input');

    for (checkboxCount = 0; checkboxCount < inputFields.length; ++checkboxCount) {
      let checkboxInput = inputFields[checkboxCount];
      let checkboxLabel = inputFields[checkboxCount].closest('.form-check');

      if(checkboxInput.getAttribute('checked') != null) {
        checkboxLabel.classList.add('checked');
      } else {
        checkboxLabel.classList.remove('checked');
      }

      checkboxInput.addEventListener('change', function() {
        checkboxLabel.classList.toggle('checked');
      });
    }
  }*/

  // Page architect slider
  const paSlider = document.querySelector('.pa--slider .image-carousel');

  if (paSlider) {
    $('.pa--slider .image-carousel').slick({
        dots: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        fade: false,
        autoplay: false,
        centerMode: true,

    });
  }

  // Product categories parallax effect
  const productCatBgs = document.querySelectorAll('.product-cat__img-content');

  if (productCatBgs) {
    let lastScrollPos = 0;
    let p = {0: -50, 1: -50, 2: -50, 3: -50};

    window.addEventListener('scroll', function(){

      for (let i = 0; i < productCatBgs.length; i++) {

        if (productCatBgs[i].offsetTop - (window.pageYOffset + window.innerHeight) + 350 <= 0) {
          if (window.pageYOffset > lastScrollPos){
            // downscroll
            if (p[i] < 0) {
              p[i] += 1;

            }
          } else {
            // upscroll
            if (p[i] > -50) {
              p[i] -= 1;
            }
          }

          productCatBgs[i].style.backgroundPosition = "center top " + p[i] + "px";
        }
      }

      lastScrollPos = window.pageYOffset <= 0 ? 0 : window.pageYOffset; // For Mobile or negative scrolling
    });
  }

  // Toggle specifications
  const toggleEl = document.querySelector('.js-toggle-content');
  const contentParent = document.querySelector('.js-content-parent');
  const content = document.querySelector('.js-content');

  if (toggleEl) {
    $(() => {
      if (contentParent.classList.contains('js-start-opened')) {
        toggleSpecs(contentParent, content);
      }
    })

    toggleEl.addEventListener('click', function (e) {
      e.preventDefault();

      toggleSpecs(contentParent, content);
    });
  }

  const toggleSpecs = (contentParent, content) => {
    if (contentParent.classList.contains('open')) {
      contentParent.classList.remove('open');
      content.style.maxHeight = '0px'
    } else {
      contentParent.classList.add('open');
      content.style.maxHeight = content.scrollHeight + 'px'
    }
  }

  // Only show locale when newsletter checkbox is checked
  const $checkbox = document.querySelector('.js-newsletter-checkbox')
  const $locale = document.querySelector('.js-newsletter-locale')

  if ($checkbox && $locale) {
    $checkbox.addEventListener('change', function (e) {
      if (e.target.checked) {
        $locale.classList.remove('d-none')
      } else {
        $locale.classList.add('d-none')
      }
    })
  }

  // Res. Rep. Create/Edit reseller form
  const $newsletterCheckbox = document.querySelector('.js-newsletter-checkbox');
  const $newsletterLocaleFormRow = document.querySelector('.js-newsletter');

  if ($newsletterCheckbox && $newsletterLocaleFormRow) {
    $newsletterCheckbox.addEventListener('change', () => {
      if ($newsletterCheckbox.checked) {
        $newsletterLocaleFormRow.classList.remove('d-none');
      } else {
        $newsletterLocaleFormRow.classList.add('d-none');
      }
    })
  }
})();

function responsive() {
  // Home product cats carousel
  const homeProductCatsCarousel = document.querySelector('.home-product-cats .image-carousel');

  if (homeProductCatsCarousel) {
    if (homeProductCatsCarousel.classList.contains('slick-initialized')) {
      $('.home-product-cats .image-carousel').slick('unslick');
    }

    if (window.innerWidth < 992) {
      var visibleSlides = 2;

      if (window.innerWidth < 576) {
          visibleSlides = 1;
      }

      $('.home-product-cats .image-carousel').slick({
          dots: true,
          arrows: false,
          speed: 500,
          slidesToShow: visibleSlides,
          autoplay: false,
          autoplaySpeed: 5000,
          centerMode: true,
      });
    }
  }

  // Home applications carousel
  const homeApplicationsCarousel = document.querySelector('.home-applications .image-carousel');

  if (homeApplicationsCarousel) {
    if (homeApplicationsCarousel.classList.contains('slick-initialized')) {
      $('.home-applications .image-carousel').slick('unslick');
    }

    if (window.innerWidth < 768) {
      visibleSlides = 1;

      $('.home-applications .image-carousel').slick({
          dots: true,
          arrows: false,
          speed: 500,
          slidesToShow: visibleSlides,
          autoplay: false,
          autoplaySpeed: 5000,
      });
    }
  }

  // Home products
  const homeProducts = document.querySelectorAll('.home .product-full');

  if (homeProducts) {

    if (window.innerWidth >= 992) {
      window.addEventListener('scroll', animateHomeProducts, homeProducts);
    }
  }

  function animateHomeProducts() {

    for (let i = 0; i < homeProducts.length; i++) {

      //The nr is the offset from the bottom of the window (350)
      if (homeProducts[i].offsetTop - (window.pageYOffset + window.innerHeight) - 50 <= 0) {
        let productImg = homeProducts[i].querySelector('.product-full__img-container');

        if (homeProducts[i].classList.contains('product-full--img-left')) {
          productImg.style.transform = "translateX(-50vw)";
        } else if (homeProducts[i].classList.contains('product-full--img-right')) {
          if (window.innerWidth >= 992) {
            productImg.style.transform = "translateX(-15px)";
          } else {
            productImg.style.transform = "translateX(50vw)";
          }
        }
      }
    }
  }

  // Home news & events carousel
  // Product assecories carousel
  const homeNewsEvents = document.querySelector('.home-news-events .image-carousel');
  const productAccessories = document.querySelector('.product-accessories .image-carousel');
  let carousel = null;
  let carouselClass = null;

  if (homeNewsEvents) {
    carousel = homeNewsEvents;
    carouselClass = '.home-news-events .image-carousel';
  } else if (productAccessories ) {
    carousel = productAccessories;
    carouselClass = '.product-accessories .image-carousel';
  }

  if (carousel) {
    var visibleSlides = 3;

    if (window.innerWidth < 992 && window.innerWidth >= 768) {
        visibleSlides = 2;
    } else if (window.innerWidth < 768) {
        visibleSlides = 1;
    }

    if (carousel.classList.contains('slick-initialized')) {
      $(carouselClass).slick('unslick');
    }

    $(carouselClass).slick({
        dots: false,
        arrows: true,
        speed: 500,
        slidesToShow: visibleSlides,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        infinite: false,
        centerMode: false,
    });
  }

  // Faq categories menu scroll
  if (faqCategoriesMenu && window.innerWidth >= 768) {
    stickyMenu();

    window.addEventListener('scroll', function() {
      stickyMenu();
    });
  }

  // Faq category scroll
  const faqCategories = document.querySelectorAll('.js-faq-category');

  if (faqCategories) {
    let topY = 140;

    if (window.innerWidth < 768) {
      topY = 120;
    } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
      topY = 110;
    } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
      topY = 125;
    }

    for (let i = 0; i < faqCategories.length; i++) {
      faqCategories[i].addEventListener("click", function(e) {
        e.preventDefault();

        let category = document.querySelector(this.getAttribute("href"));

        // window.scrollTo({
        //   'behavior': 'smooth',
        //   'left': 0,
        //   'top': category.offsetParent.offsetTop + category.offsetTop - 140
        // });

        scrollIt(
          category.offsetParent.offsetTop + category.offsetTop - topY,
          300,
          'easeOutQuad',
          null
        );
      });
    }
  }

  // Toggle bar with hidden content
  const toggleBars = document.querySelectorAll('.js-toggle-bar');

  if (toggleBars) {
    for (let i = 0; i < toggleBars.length; i++) {
      if (toggleBars[i].classList.contains('open')) {
        toggleBars[i].nextElementSibling.style.maxHeight = toggleBars[i].nextElementSibling.scrollHeight + "px";
      }

      toggleBars[i].addEventListener("click", function(e) {
        e.currentTarget.classList.toggle("open");
        const barContent = e.currentTarget.nextElementSibling;

        if (e.currentTarget.classList.contains("open")){
          barContent.style.maxHeight = barContent.scrollHeight + "px";
        } else {
          barContent.style.maxHeight = 0;
        }
      });
    }
  }

  if (window.innerWidth >= 768) {
    const $parallaxEls = document.querySelectorAll('.js-parallax')
    if ($parallaxEls.length > 0) {
      for (let i = 0; i < $parallaxEls.length; i++) {
        $($parallaxEls[i]).parallax({imageSrc: $parallaxEls[i].dataset.imageSrc});
      }
    }
  }
}

const $carouselContainer = document.querySelector('.js-carousel')

if ($carouselContainer) {
  $('.js-carousel').slick({
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    fade: true,
    autoplay: false
});
}


function stickyMenu() {
  let bottomFaqs = faqs.offsetTop + faqs.offsetHeight;

  if (sticky && faqs.offsetTop - window.pageYOffset - header.offsetHeight - 15 >= 0) {
    faqCategoriesMenu.style.cssText = "position: relative;";
    sticky = false;
  } else if ((sticky && window.pageYOffset >= bottomFaqs - (faqCategoriesMenu.offsetHeight + header.offsetHeight))) {
    faqCategoriesMenu.style.cssText = "position: absolute; bottom: 0; width: 100%";
    sticky = true;
  } else if ((!sticky && faqs.offsetTop - window.pageYOffset - header.offsetHeight <= 0)
    || (sticky && window.pageYOffset <= bottomFaqs - (faqCategoriesMenu.offsetHeight + header.offsetHeight + 15))) {
    faqCategoriesMenu.style.cssText = "width: " + faqCategoriesMenu.offsetWidth - 30 + "px; position: fixed; top: " + (header.offsetHeight + 10) + "px";
    sticky = true;
  }
}

/**
 *
 * @param {(number|HTMLElement)} destination - Destination to scroll to (DOM element or number)
 * @param {number} duration - Duration of scrolling animation
 * @param {string} easing - Timing function name (Allowed values: 'linear', 'easeInQuad', 'easeOutQuad', 'easeInOutQuad', 'easeInCubic', 'easeOutCubic', 'easeInOutCubic', 'easeInQuart', 'easeOutQuart', 'easeInOutQuart', 'easeInQuint', 'easeOutQuint', 'easeInOutQuint')
 * @param {function} callback - Optional callback invoked after animation
 */
function scrollIt(destination, duration = 200, easing = 'linear', callback) {

  // Predefine list of available timing functions
  // If you need more, tween js is full of great examples
  // https://github.com/tweenjs/tween.js/blob/master/src/Tween.js#L421-L737
  const easings = {
    linear(t) {
      return t;
    },
    easeInQuad(t) {
      return t * t;
    },
    easeOutQuad(t) {
      return t * (2 - t);
    },
    easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic(t) {
      return t * t * t;
    },
    easeOutCubic(t) {
      return (--t) * t * t + 1;
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart(t) {
      return t * t * t * t;
    },
    easeOutQuart(t) {
      return 1 - (--t) * t * t * t;
    },
    easeInOutQuart(t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
    },
    easeInQuint(t) {
      return t * t * t * t * t;
    },
    easeOutQuint(t) {
      return 1 + (--t) * t * t * t * t;
    },
    easeInOutQuint(t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
    }
  };


  // Store initial position of a window and time
  // If performance is not available in your browser
  // It will fallback to new Date().getTime() - thanks IE < 10
  const start = window.pageYOffset;
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
  // const startTime = typeof(window.performance['now']) == 'function' ? performance.now() : new Date().getTime();


  // Take height of window and document to sesolve max scrollable value
  // Prevent requestAnimationFrame() from scrolling below maximum scollable value
  // Resolve destination type (node or number)
  const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
  const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
  const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);


  // If requestAnimationFrame is not supported
  // Move window to destination position and trigger callback function
  if ('requestAnimationFrame' in window === false) {
    window.scroll(0, destinationOffsetToScroll);
    if (callback) {
      callback();
    }
    return;
  }


  // function resolves position of a window and moves to exact amount of pixels
  // Resolved by calculating delta and timing function choosen by user
  function scroll() {
    const now = 'now' in window.performance ? performance.now() : new Date().getTime();
    const time = Math.min(1, ((now - startTime) / duration));
    const timeFunction = easings[easing](time);
    window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

    // Stop requesting animation when window reached its destination
    // And run a callback function
    if (window.pageYOffset === destinationOffsetToScroll) {
      if (callback) {
        callback();
      }
      return;
    }

    // If window still needs to scroll to reach destination
    // Request another scroll invokation
    requestAnimationFrame(scroll);
  }

  // Invoke scroll and sequential requestAnimationFrame
  scroll();
}
