$(function () {
  const $productsSolutionsSliderContainers = $(
    '.js-products-solutions-new-slider-container'
  );

  if ($productsSolutionsSliderContainers.length) {
    $productsSolutionsSliderContainers.each(function () {
      const $productsSolutionsSliderContainer = $(this);
      const $slider = $productsSolutionsSliderContainer
        .find('.js-products-solutions-new-slider')
        .slick({
          dots: false,
          arrows: false,
          speed: 500,
          slidesToScroll: 1,
          autoplay: false,
          infinite: false,
          centerMode: false,
        });

      $slider.on(
        'beforeChange',
        function (event, slick, currentSlide, nextSlide) {
          $productsSolutionsSliderContainer
            .find('.js-products-solutions-new-slider-nav-item')
            .removeClass('active')
            .eq(nextSlide)
            .addClass('active');
        }
      );

      $productsSolutionsSliderContainer
        .find('.js-products-solutions-new-slider-nav-item')
        .each(function ($index, $item) {
          $item.addEventListener('click', function () {
            $slider.slick('slickGoTo', $index);
          });
        });
    });
  }
});
